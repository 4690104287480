import axios from 'axios';
import dayjs from 'dayjs';
import {
    buildStartEndDateUrl, buildUrlParams, createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { getApiUrl } from '@/utils/api';

const state = {
    ...getDefaultDataState({ hasPagination: false }),
    countData: null,
};

const getters = {
    ...getDefaultDataGetters({ hasPagination: false }),

    getCountData(state) {
        return state.countData;
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: false }),
    setCountData(state, payload) {
        state.countData = payload;
    },
};

const actions = {
    async loadData({
        state, commit, rootState, rootGetters,
    }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
        });

        const path = buildStartEndDateUrl({
            path: 'hsafety/detections/summary',
            startDate,
            endDate,
        });

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: 'loading' });

        try {
            const response = await axios({
                url: getApiUrl({ path }),
                params,
            });
            commit('setData', { data: response.data });
            commit('setDataHash', { dataHash });

            const riskTypes = ['ExclusionZone', 'OverSpeed-GroupB', 'OverSpeed-GroupC', 'DistanceZone-Public', 'DistanceZone-Worker'];
            const countRequests = riskTypes.map((riskType) => (async () => {
                const response = await axios(
                    getApiUrl({
                        path: 'hsafety/observations/count',
                    }),
                    {
                        params: {
                            risk_filter_type: riskType,
                            observation_filter_from: dayjs(startDate).format('YYYY-MM-DDTHH:mm:00'),
                            observation_filter_to: dayjs(endDate).format('YYYY-MM-DDTHH:mm:00'),
                        },
                    },
                );
                return { data: response.data, riskType };
            })());

            const countData = await Promise.all(countRequests);
            commit('setCountData', countData);
            commit('setStatus', { status: 'success' });
        } catch (e) {
            commit('setStatus', { status: 'error' });
        }

        return Promise.resolve(true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
