import { get, lowerCase } from 'lodash';
import * as turf from '@turf/turf';
import store from '@/store/store.js';

const Zones = {
    Default: 0,
    Safety: 1,
    Exclusion: 2,
};

function isObjectInsideZones(objectBB, zoneType = Zones.Default) {
    if (!objectBB || objectBB.length === 0) {
        return false;
    }

    const polygons = store.getters['canvas/zoneRois'](zoneType)?.roi || [];

    // Python's int() method trims values after the decimal point:
    // the same as applying Math.floor()
    const bottom = Math.floor(objectBB.t + 0.8 * (objectBB.b - objectBB.t));
    const bb = turf.bboxPolygon([objectBB.l, bottom, objectBB.r, objectBB.b]);

    const intersection = polygons.find((polygon) => {
        const first = polygon[0];
        const converted = turf.polygon([[...polygon, first]]);

        return turf.intersect(bb, converted) !== null;
    });

    return intersection !== undefined;
}

export function isObjectInZoneList(zoneList, zoneType = Zones.Default) {
    return zoneList.includes(store.getters['canvas/zoneRois'](zoneType)?.id || '');
}

export function getPPEDetections(result) {
    const settings = store.getters['canvas/ppeSettings'] ?? null;
    const tagNames = get(result, 'tags', []).map((t) => lowerCase(`${t.name}`));
    const objectClass = get(result, 'object_class', '').toLowerCase();
    const detection = get(result, 'detection', '').toLowerCase();
    const ppeDetections = [];

    if (objectClass === 'forklift' || detection === 'forklift') {
        return ppeDetections;
    }

    if (objectClass === 'person') {
        if (!tagNames.includes('vis') && settings && settings.detectVis) {
            ppeDetections.push('vis');
        }

        if (!tagNames.includes('hat') && settings && settings.detectHat) {
            ppeDetections.push('hat');
        }
    }

    if (objectClass === 'car') {
        if (result.speed && result.speed > settings.temporarySpeedLimit) {
            ppeDetections.push('overspeed');
        } else if (!result.speed && result.tags.includes('Overspeed')) {
            ppeDetections.push('overspeed');
        }
    }

    return ppeDetections;
}

export function ppeDetectionCount(result) {
    return getPPEDetections(result).length;
}

export function isViolation(result, { bb = [], zoneList = [] } = {}) {
    const objectClass = get(result, 'object_class', '').toLowerCase();

    if (objectClass === 'car') {
        return ppeDetectionCount(result) > 0;
    }

    const checkSafety = (condition) => condition && ppeDetectionCount(result) > 0;
    const checkExclusion = (condition) => condition;

    const isViolationInBB = checkSafety(isObjectInsideZones(bb, Zones.Safety))
        || checkExclusion(isObjectInsideZones(bb, Zones.Exclusion));
    const isViolationInZoneList = checkSafety(isObjectInZoneList(zoneList, Zones.Safety))
        || checkExclusion(isObjectInZoneList(zoneList, Zones.Exclusion));

    return isViolationInBB || isViolationInZoneList;
}
