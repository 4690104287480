<template>
    <b-taglist
        class="confidence-list__item confidence-tag  has-cursor-default"
        attached
    >
        <b-tag class="confidence-tag__confidence is-marginless" :class="decimal ? 'has-decimal' : null">
            <!-- <span class="confidence-tag__confidence__type" :class="type" /> -->
            <span class="confidence-tag__confidence__label">{{ formatted }}</span>
        </b-tag>
    </b-taglist>
</template>

<script>
import { getConfidenceType } from '@/utils/confidence';
import { formatPercentage } from '@/utils/formatters/numbers';

export default {
    props: {
        confidence: {
            type: [String, Number],
            required: true,
        },
        decimal: {
            type: Boolean,
            default: false,
        },
        time: {
            type: String,
            default: null,
        },
        hasPredictionConfirmation: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            predictionsActive: false,
        };
    },

    computed: {
        formatted() {
            return formatPercentage({ number: this.confidence, decimal: this.decimal });
        },
        type() {
            return getConfidenceType({ confidence: this.confidence });
        },
    },

    methods: {
        togglePredictions(force = null) {
            this.predictionsActive = force === null ? !this.predictionsActive : force;
        },

        onPredictionClick() {
            this.$refs.tooltip.keyPress({ key: 'Esc' });
        },
    },
};
</script>

<style lang="scss" scoped>
.confidence-list {
    display: flex;
    position: relative;
}

.confidence-tag {
    white-space: nowrap;
    flex-wrap: nowrap;
    flex: 0 0 auto;

    ::v-deep .tag {
        border-radius: $radius;
    }

    &__confidence {
        font-weight: $weight-bold;
        color: $white;
        background: rgba($black-bis, .8);
        padding-left: 8px;
        padding-right: 2px;
        text-align: left;
        justify-content: flex-start;
        width: 54px;

        &.has-decimal {
            width: 65px;
        }

        ::v-deep > span {
            display: inline-flex;
            align-items: center;
            flex-wrap: nowrap;
            line-height: 1;
        }

        &__type {
            width: 7px;
            height: 7px;
            border-radius: 7px;
            @include confidence-bg;
            display: block;
            margin-right: 4px;
        }
    }
}
</style>
