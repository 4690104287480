<template>
    <mgl-marker
        :coordinates="coordinates"
        :draggable="false"
    >
        <div slot="marker">
            <div class="map-marker" :class="{ 'has-popup-opened': isPopupOpened, 'has-popup': hasPopup }">
                <div ref="icon" class="map-marker__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="45" viewBox="0 0 39 45">
                        <g fill="none" fill-rule="evenodd">
                            <path
                                fill="#07C3FF"
                                d="M20.2557,44.0464 C24.1751,41.5495 38.5592,31.5501 38.5592,19.2796 C38.5592,8.63177 29.9274,0 19.2796,0 C8.6318,0 0,8.63177 0,19.2796 C0,31.5501 14.3841,41.5495 18.3035,44.0464 C18.9076,44.4313 19.6516,44.4313 20.2557,44.0464 Z" />
                            <path
                                fill="#FFF"
                                d="M19.2796,25.3679 C22.6421,25.3679 25.3679,22.6421 25.3679,19.2796 C25.3679,15.9171 22.6421,13.1913 19.2796,13.1913 C15.9171,13.1913 13.1913,15.9171 13.1913,19.2796 C13.1913,22.6421 15.9171,25.3679 19.2796,25.3679 Z" />
                        </g>
                    </svg>
                </div>
                <div
                    ref="info"
                    class="map-marker__info"
                    :class="{ 'is-right': displayOnRight }"
                >
                    <div
                        v-if="hasLabel"
                        class="map-marker__text"
                        v-html="markerText"
                    />
                    <be-camera-status class="mt-3" v-if="status" :status="status" />
                </div>
            </div>
        </div>

        <mgl-popup
            v-if="hasPopup"
            :showed="isPopupOpened"
            class="site-popup"
            ref="popup"
            :close-button="false"
            @open="popupOpen"
            @close="popupClose"
            :offset="[0, 0]"
        >
            <div class="site-marker">
                <div class="site-marker__inner">
                    <header>
                        <h4 class="title-widget mb-1" v-text="item.name" />
                        <div class="label-sub-text" v-text="item.address" />
                        <be-camera-status class="mt-3" v-if="status" :status="status" />
                    </header>
                    <camera-last-detection :camera="lastDetectionCamera" class="my-3" :display-label="true" />
                    <hr>
                    <div class="site-marker__footer">
                        <div class="site-marker__footer__cta">
                            <b-button
                                @click.prevent="viewDetections"
                                :disabled="status === 'pending'"
                                class="be-button is-primary"
                                expanded
                            >
                                View detections
                            </b-button>
                        </div>
                        <div class="site-marker__footer__cta">
                            <b-button
                                tag="router-link"
                                :to="`/dashboard/sites/${item.id}`"
                                class="be-button is-secondary"
                                expanded
                            >
                                Site details
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </mgl-popup>
    </mgl-marker>
</template>

<script>
import { MglMarker, MglPopup } from 'v-mapbox';
import siteCardMixin from '@/mixins/siteCardMixin';
import { getDetectionsByTagUrl } from '@/utils/detections';
import BeCameraStatus from '@/components/global/BeCameraStatus';
import CameraLastDetection from '@/components/local/sites/partials/CameraLastDetection';

export default {
    components: {
        CameraLastDetection,
        BeCameraStatus,
        MglMarker,
        MglPopup,
    },

    mixins: [
        siteCardMixin,
    ],

    props: {
        marker: {
            type: Object,
            default: () => ({}),
        },
        hasPopup: {
            type: Boolean,
            default: true,
        },
        hasLabel: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isPopupOpened: false,
            displayOnRight: false,
        };
    },

    computed: {
        coordinates() {
            return [
                this.item.lng,
                this.item.lat,
            ];
        },
        markerText() {
            return this.item.name.length <= 12
                ? this.item.name
                : `${this.item.name.slice(0, 12)}&hellip;`;
        },
    },

    watch: {
        isPopupOpened(val) {
            if (val) {
                this.$nextTick(() => {
                    this.$refs.popup.popup.setMaxWidth('400px');
                });
            }
        },
    },

    mounted() {
        this.loadSite();
        this.updateMarketInfoPosition();

        this.$parent.map.on('moveend', this.updateMarketInfoPosition);
        this.$bus.$on('resize', this.updateMarketInfoPosition);
        this.$once('hook:beforeDestroy', () => {
            this.$bus.$off('resize', this.updateMarketInfoPosition);
            this.$parent.map.off('moveend', this.updateMarketInfoPosition);
        });
    },

    methods: {
        popupOpen() {
            this.isPopupOpened = true;
        },
        popupClose() {
            this.isPopupOpened = false;
        },

        viewDetections() {
            this.popupClose();
            this.$router.push(getDetectionsByTagUrl({
                tags: this.item.tags,
            }));
        },

        updateMarketInfoPosition() {
            this.$nextTick(() => {
                this.displayOnRight = !this.hasEnoughRightSpaceInViewport();
            });
        },
        hasEnoughRightSpaceInViewport() {
            if (!this.$refs.info || !this.$refs.icon) {
                return true;
            }

            const bound = this.$refs.icon.getBoundingClientRect();
            const infoBound = this.$refs.info.getBoundingClientRect();

            // give some extra space
            return (bound.right + infoBound.width + 10) < window.innerWidth;
        },
    },
};
</script>

<style lang="scss" scoped>
.site-marker {
    font-family: $family-primary;
    width: 370px;

    @include mobile {
        width: 260px;
    }

    &__inner {
        padding: 10px;
        @include mobile {
            padding: 5px;
        }
    }

    &__footer {
        @include tablet {
            display: flex;
            align-items: stretch;
        }

        &__cta {
            @include tablet {
                flex: 1;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
}

.map-marker {
    display: block;
    position: relative;

    &__info,
    &__icon-bg {
        transition: all $speed $easing;
    }

    &__icon {
        display: block;
        width: 39px;
        height: 45px;

        svg {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &.has-popup-opened &__info {
        opacity: 0;
        pointer-events: none;
    }

    &__info {
        left: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 5px;
        text-align: left;
        background-color: $grey-darker;
        color: $white;
        padding: 12px 16px;
        border-radius: $radius;

        &.is-right {
            left: auto;
            right: 100%;
            margin-left: 0;
            margin-right: 5px;
        }
    }

    &__text {

        font-weight: $weight-normal;
        font-size: 16px;
        min-width: 200px;
        letter-spacing: 0.01em;
        line-height: 1;
        font-family: $family-primary;
        line-height: 1;
    }

    cursor: default;

    &.has-popup {
        cursor: pointer;
    }

    &.has-popup:hover &,
    &.has-popup-opened & {
        &__icon-bg {
            fill: $grey-darker;
        }

        &__text {
            background: $grey-darker;
        }
    }
}
</style>
