<template>
    <div class="totals columns is-multiline">
        <div class="totals__col column is-6-tablet is-4-desktop">
            <be-widget icon="alert-outline" iconType="is-light">
                <template v-slot:heading>Total exclusion zone detections</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ exclusionCount }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-4-desktop">
            <be-widget icon="vector-line" iconType="is-light">
                <template v-slot:heading>Close people/forklift interactions</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ distanceCount }}</p>
                </template>
            </be-widget>
        </div>
        <div class="totals__col column is-6-tablet is-4-desktop">
            <be-widget icon="speedometer" iconType="is-light">
                <template v-slot:heading>Speed detections (>20km/h)</template>
                <template v-slot:content>
                    <p class="label-widget-number">{{ speedCount }}</p>
                </template>
            </be-widget>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { flatten } from 'lodash';
import { formatNumber } from '@/utils/formatters/numbers';
import widgetDataMixin from '@/mixins/widgetDataMixin';

export default {
    mixins: [widgetDataMixin],

    data() {
        return {
            exclusionCount: '-',
            speedCount: '-',
            distanceCount: '-',
        };
    },

    watch: {
        isLoading(newValue) {
            if (!newValue) {
                this.updateCounts();
            }
        },
    },

    computed: {
        ...mapGetters('hs/summary', ['getCountData', 'isLoading']),
    },

    methods: {
        ...mapActions('hs/summary', ['loadData']),

        updateCounts() {
            this.exclusionCount = this.totalExclusionZoneDetections();
            this.speedCount = this.totalSpeedDetections();
            this.distanceCount = this.totalForkliftInteractions();
        },

        totalExclusionZoneDetections() {
            const totalDetections = flatten(this.getCountData
                .filter((item) => item.riskType === 'ExclusionZone')
                .map((item) => item.data.map((dataItem) => dataItem.violation_count)))
                .reduce((a, b) => a + b, 0);
            return formatNumber({ number: totalDetections });
        },

        totalForkliftInteractions() {
            const totalDetections = flatten(this.getCountData
                .filter((item) => item.riskType === 'DistanceZone-Public'
                    || item.riskType === 'DistanceZone-Worker')
                .map((item) => item.data.map((dataItem) => dataItem.violation_count)))
                .reduce((a, b) => a + b, 0);
            return formatNumber({ number: totalDetections });
        },
        totalSpeedDetections() {
            const totalDetections = flatten(this.getCountData
                .filter((item) => item.riskType === 'OverSpeed-GroupB'
                    || item.riskType === 'OverSpeed-GroupC')
                .map((item) => item.data.map((dataItem) => dataItem.violation_count)))
                .reduce((a, b) => a + b, 0);
            return formatNumber({ number: totalDetections });
        },
    },
};
</script>

<style lang="scss" scoped>
.totals {
    justify-content: stretch;

    &__col {
        display: flex;
        justify-content: stretch;
    }
}
</style>
