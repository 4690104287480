<template>
    <div
        class="confidence-list"
        :class="{
            'has-wrap': wrap, 'is-clickable': clickable, 'has-cursor-pointer': clickable, 'has-cursor-default': !clickable,
        }"
        @click="onClick"
        @keydown="onKeyDown"
    >
        <be-detection-time
            v-if="time"
            :time="time"
            format="YYYY-MM-DD"
            class="confidence-list__item has-no-time"
        />
        <b-taglist
            class="confidence-list__item confidence-tag"
            :class="{
                'has-wrap': wrap,
                'has-count': count,
                'has-object-type': objectType,
            }"
            attached
        >
            <b-tag v-if="count" class="confidence-tag__count is-marginless">
                {{ count }}
            </b-tag>
            <b-tag v-if="objectType" class="confidence-tag__object is-marginless">
                {{ objectType }}
            </b-tag>

            <template v-if="confidence">
                <b-tooltip
                    v-if="hasPredictionConfirmation"
                    type="is-dark"
                    position="is-bottom"
                    :triggers="['click']"
                    :auto-close="['outside', 'escape', 'boolean']"
                    ref="tooltip"
                >
                    <template v-slot:content>
                        <be-prediction-confirmation @click="onPredictionClick" />
                    </template>
                    <b-tag
                        class="confidence-tag__confidence is-marginless has-cursor-pointer"
                        :class="decimal ? 'has-decimal' : null">
                        <!-- <span class="confidence-tag__confidence__type" :class="type" /> -->
                        <span class="confidence-tag__confidence__label">{{ formatted }}</span>
                    </b-tag>
                </b-tooltip>
                <template v-else>
                    <b-tag class="confidence-tag__confidence is-marginless" :class="decimal ? 'has-decimal' : null">
                        <!-- <span class="confidence-tag__confidence__type" :class="type" /> -->
                        <span class="confidence-tag__confidence__label">{{ formatted }}</span>
                    </b-tag>
                </template>
            </template>
        </b-taglist>
    </div>
</template>

<script>
import { getConfidenceType } from '@/utils/confidence';
import { formatPercentage } from '@/utils/formatters/numbers';
import BeDetectionTime from '@/components/global/BeDetectionTime';
import BePredictionConfirmation from '@/components/global/BePredictionConfirmation';

export default {
    components: {
        BePredictionConfirmation,
        BeDetectionTime,
    },

    props: {
        count: {
            type: [String, Number],
            default: null,
        },
        objectType: {
            type: String,
            default: null,
        },
        confidence: {
            type: [String, Number],
            required: true,
        },
        decimal: {
            type: Boolean,
            default: false,
        },
        time: {
            type: String,
            default: null,
        },
        wrap: {
            type: Boolean,
            default: false,
        },
        hasPredictionConfirmation: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            predictionsActive: false,
        };
    },

    computed: {
        formatted() {
            return formatPercentage({ number: this.confidence, decimal: this.decimal });
        },
        type() {
            return getConfidenceType({ confidence: this.confidence });
        },
    },

    methods: {
        togglePredictions(force = null) {
            this.predictionsActive = force === null ? !this.predictionsActive : force;
        },

        onPredictionClick() {
            this.$refs.tooltip.keyPress({ key: 'Esc' });
        },

        onClick() {
            if (this.clickable) {
                this.$emit('click');
            }
        },
        onKeyDown(event) {
            if (event.key === 'Enter' || event.key === ' ') {
                this.onClick();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.confidence-list {
    display: flex;
    margin-left: -3px;
    margin-right: -3px;
    position: relative;

    &.has-wrap {
        flex-wrap: wrap;
    }

    &__item {
        margin: 3px;
    }
}

.confidence-tag {
    white-space: nowrap;
    flex-wrap: nowrap;
    flex: 0 0 auto;

    ::v-deep .tag {
        border-radius: 0;

        &:first-child {
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
        }

        &:last-child {
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;
        }
    }

    &__object,
    &__count {
        padding-left: 8px;
        padding-right: 8px;
        min-width: 40px;
        font-weight: $weight-bold;
        text-align: center;
        justify-content: center;

    }

    &__object {
        background: $blue;
        color: $black-bis;
        border-radius: 0;
    }

    &__count {
        background: $grey;
        color: $white;
    }

    &__confidence {
        font-weight: $weight-bold;
        color: $white;
        background: rgba($black-bis, .8);
        padding-left: 8px;
        padding-right: 2px;
        text-align: left;
        justify-content: flex-start;
        width: 54px;

        &.has-decimal {
            width: 65px;
        }

        ::v-deep > span {
            display: inline-flex;
            align-items: center;
            flex-wrap: nowrap;
            line-height: 1;
        }

        &__type {
            width: 7px;
            height: 7px;
            border-radius: 7px;
            @include confidence-bg;
            display: block;
            margin-right: 4px;
        }
    }
}
</style>
