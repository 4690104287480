import dayjs from 'dayjs';
import axios from 'axios';
import { find } from 'lodash';
import { STATUS_LOADING, STATUS_SUCCESS } from '@/utils/constants';
import { DATETIME_FORMATTED_FORMAT } from '@/utils/formatters/datetime';
import { getApiUrl } from '@/utils/api';
import speed from './speed';

const getDefaultState = () => ({
    status: '',
    filtersApplied: {},
    data: null,
    countData: null,
});

const state = getDefaultState();

const getters = {
    isLoading: (state) => state.status === STATUS_LOADING,
    isSuccess: (state) => state.status === STATUS_SUCCESS,

    getAppliedFilters(state) {
        return state.filtersApplied;
    },

    getData(state) {
        return state.data;
    },

    apiResults(state) {
        return state.data;
    },

    filtersSummary(state, getters, rootState, rootGetters) {
        if (!getters.hasFiltersApplied) {
            return '';
        }
        const { observation } = state.filtersApplied;

        let str = 'Overview for';

        const site = find(rootState.hs.observations.observationFilters.sites, { id: observation.site });
        if (site) {
            str += ` site "${site.name}"`;
        }

        const camera = find(rootGetters['hs/observations/observationFilters/currentSiteCameras'], {
            id: observation.camera,
        });
        if (camera) {
            str += ` and camera "${camera.name}"`;
        }

        str += ` between ${dayjs(observation.from).format(DATETIME_FORMATTED_FORMAT)}`;
        str += `  and ${dayjs(observation.to).format(DATETIME_FORMATTED_FORMAT)}`;

        return str;
    },

    hasFiltersApplied(state) {
        return !!(state.filtersApplied && Object.values(state.filtersApplied).length);
    },
};

const mutations = {
    setData(state, payload) {
        state.data = payload;
    },
    setStatus(state, payload) {
        state.status = payload.status;
    },
    applyFilters(state, payload) {
        state.filtersApplied = {
            ...payload,
        };
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};
const actions = {
    filter({ state, commit }, payload) {
        if (state.status === STATUS_LOADING) {
            return Promise.resolve();
        }

        commit('setStatus', { status: STATUS_LOADING });
        commit('applyFilters', payload.filters || {});

        return new Promise((resolve) => {
            setTimeout(() => {
                // commit('setData', { data: { dates: [] } });
                commit('setStatus', { status: STATUS_SUCCESS });
                resolve();
            }, 1000);
        });
    },
    async loadData({ state, commit }) {
        const appliedFilters = state.filtersApplied?.observation || {};
        commit('setStatus', { status: STATUS_LOADING });
        if (appliedFilters.riskType !== undefined) {
            const requests = appliedFilters.riskType.map((riskType) => (async () => {
                const response = await axios(
                    getApiUrl({
                        path: 'hsafety/observations/summary',
                    }),
                    {
                        params: {
                            risk_filter_type: riskType.handle,
                            observation_filter_site: appliedFilters.selectedSites[0].id,
                            observation_filter_camera: appliedFilters.selectedCameras.flatMap((cameras) => cameras.id),
                            observation_filter_from: dayjs(appliedFilters.from).format('YYYY-MM-DDTHH:mm:00'),
                            observation_filter_to: dayjs(appliedFilters.to).format('YYYY-MM-DDTHH:mm:00'),
                        },
                    },
                );
                return { data: response.data, riskType: riskType.handle };
            })());

            const data = await Promise.all(requests);
            commit('setData', data);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        speed,
    },
};
