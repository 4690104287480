<template>
    <be-widget class="chart" :is-loading="isLoading" :content-classes="['is-align-bottom']">
        <template v-slot:heading>
            {{ widgetTitle }}
        </template>
        <template v-slot:content>
            <div class="chart__chart">
                <bar-chart class="chart__wrapper" :chart-data="chartData" :options="options" />
            </div>
        </template>
    </be-widget>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BarChart from '@/utils/chartjs/BarChart';
import widgetDataMixin from '@/mixins/widgetDataMixin';
import { CHART_COLORS, COLORS } from '@/utils/constants';

const getChartDataDefaults = () => ({
    labels: [],
    datasets: [
        {
            label: 'People/Forklift interactions',
            backgroundColor: COLORS.darkgreen,
            data: [],
        },
        {
            label: 'Speed detections',
            backgroundColor: COLORS.lightgreen,
            data: [],
        },
        {
            label: 'Exclusion zone detections',
            backgroundColor: COLORS.gold,
            data: [],
        },
    ],
});

export default {
    components: {
        BarChart,
    },

    mixins: [widgetDataMixin],

    data() {
        return {
            chartData: getChartDataDefaults(),
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            suggestedMin: 0,
                        },
                        gridLines: {
                            drawOnChartArea: true,
                        },
                    }],
                },
            },
        };
    },

    computed: {
        ...mapGetters('dataFilters', ['getDateRange']),
        ...mapGetters('hs/summary', ['isLoading', 'getCountData']),

        widgetTitle() {
            return 'Safety detection events by camera';
        },
    },

    watch: {

        getCountData: {
            handler(newValue) {
                if (newValue) {
                    this.updateChartData();
                }
            },
            immediate: false,
            deep: true,
        },
    },

    methods: {
        ...mapActions('hs/observations/summary', ['loadData']),

        canPopulateChart() {
            return this.getDateRange.start && this.getDateRange.end
                && this.getDateRange.start <= this.getDateRange.end;
        },

        getApiResultsDatasets() {
            console.log('getApiResultsDatasets');
            const evaluateCameraMapForMetric = (riskType, initialMap) => {
                const metricList = this.getCountData.filter((item) => item.riskType === riskType)[0];
                return metricList.data.reduce((p, curr) => {
                    const rM = p;
                    if (rM[curr.violation_camera] === undefined) {
                        rM[curr.violation_camera] = {};
                    }
                    rM[curr.violation_camera][riskType] = curr.violation_count;
                    return rM;
                }, initialMap);
            };

            let cameraDataMap = evaluateCameraMapForMetric('DistanceZone-Worker', {});
            cameraDataMap = evaluateCameraMapForMetric('OverSpeed-GroupB', cameraDataMap);
            cameraDataMap = evaluateCameraMapForMetric('ExclusionZone', cameraDataMap);

            const cameras = Object.keys(cameraDataMap);
            const createDatasetForMetric = (riskType, cameraDataMap) => cameras.map((x) => (cameraDataMap[x][riskType]
                ? cameraDataMap[x][riskType] : 0));

            const datasets = [
                {
                    label: 'People/Forklift interactions',
                    backgroundColor: CHART_COLORS.darkgreen,
                    data: createDatasetForMetric('DistanceZone-Worker', cameraDataMap),
                },
                {
                    label: 'Speed detections',
                    backgroundColor: CHART_COLORS.lightgreen,
                    data: createDatasetForMetric('OverSpeed-GroupB', cameraDataMap),
                },
                {
                    label: 'Exclusion zone detections',
                    backgroundColor: CHART_COLORS.gold,
                    data: createDatasetForMetric('ExclusionZone', cameraDataMap),
                },
            ];

            return {
                cameras,
                datasets,
            };
        },

        updateChartData() {
            if (!this.canPopulateChart()) {
                this.resetChartData();
                return;
            }

            this.options.legend.display = true;
            // to overcome the problem of non-reactive deep updates
            this.$nextTick(() => {
                const apiData = this.getApiResultsDatasets();
                this.chartData = {
                    labels: apiData.cameras,
                    datasets: apiData.datasets,
                };
            });
        },

        resetChartData() {
            this.chartData = getChartDataDefaults();
        },
    },
};
</script>

<style lang="scss" scoped>
.chart {
    &__toggler {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    &__wrapper {
        position: relative;
        height: 500px;
        width: 100%;
    }
}
</style>
